import {
    Box,
    Card,
    CardContent,
    Avatar,
    Grid, } from '@mui/material'
import { Header, Footer, Background } from '../containers'
import { DefaultTypography } from '../components/StyledTypographies'
import { StarStaticContainer } from '../components/StyledBox'

import { useParams } from 'react-router-dom'

export default function StudentEvaluation() {
    const params = useParams()

    return (
        <Background>
            <Header />
            <Box sx={{display: "flex", flexDirection: { xs: "column", sm: "column", md: "column" }, margin: "5px"}}>
                <Card sx={{
                    display: "flex",
                    width: "auto",
                    height: "100%",
                    justifyContent: "center",
                    flexDirection: {
                        xs: "column",
                        sm: "column",
                        md: "row",
                        lg: "row",
                    },
                }}>
                    <CardContent sx={{display: "flex",
                        width: "auto",
                        height: "100%",
                        justifyContent: "center",
                        flexDirection: {
                        xs: "column",
                        sm: "column",
                        md: "column",
                        lg: "row",
                        }}}
                    >
                    <Box sx={{
                        minWidth: "205px",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        textAlign: "center",
                        flexDirection: "column",
                    }}>
                        <Avatar sx={{height: "auto", minWidth: "100px", margin: "10px", }} />
                            {/* ////src image here */}
                        <StarStaticContainer rating={params.rating} />
                        <DefaultTypography>
                            {params.review}
                        </DefaultTypography>
                    </Box>
                    <Box sx={{display: "flex", flexDirection: "column", margin: "15px",}}>
                        <DefaultTypography>
                            {params.studentID+"      "}
                        </DefaultTypography>

                        <DefaultTypography>
                            {params.section}
                        </DefaultTypography>
                        <Box>
                            <Grid container sx={{ display: "flex", flexDirection: "row" }}>
                                <Grid sx={{ width: "50%" }} />
                                <Grid sx={{ minWidth: "50%" }} />
                            </Grid>
                        </Box>
                    </Box>
                    <Box sx={{minWidth: "180px", display: "flex", alignItems: "center", textAlign: "center", margin: "15px",}}>
                        <Grid container>
                            <Grid sx={{ width: "50%" }}>
                                <DefaultTypography>
                                    Teamwork:
                                    <br />
                                    Creativity:
                                    <br />
                                    Adaptability:
                                    <br />
                                    Leadership:
                                    <br />
                                    Persuasion:
                                </DefaultTypography>
                            </Grid>
                            <Grid sx={{ width: "50%" }} />
                        </Grid>
                    </Box>
                    </CardContent>
                </Card>
            </Box>
            <Footer />
        </Background>
    )
}
