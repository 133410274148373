import * as actionTypes from '../type'

const initialState = {
    list: null,
}

function studentReducer (state=initialState, action){
    switch(action.type) {
        case actionTypes.SET_DOCUMENTS:
            return {
                ...state,
                list: action.payload
            }
        case actionTypes.ON_ERROR:
            return {
                ...state,
            }
        default:
            return {
                ...state,
            }
    }
}

export default studentReducer