import { useTheme } from '@mui/material'

export const ListStyle  = () => {
    const theme = useTheme()
    
    return {
        containerLink: {
            textDecoration: 'none',
            color: theme.palette.text.primary,
            margin: '0 48px',
        },
        containerActive: {
            textDecoration: 'none',
            borderTop: theme.palette.primary.main+' solid 5px',
            borderRadius: '5%',
            color: theme.palette.primary.main,
            margin: '0 48px',
        },
    }
}
export const Container = () => ({
    containerNavBar: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flex: '1 1 auto',
        listStyle: 'none',
        padding: '20px',
    },
    containerHeader: {
        backgroundColor: 'background.default',
        display: 'flex',
        flex: '33% 0 auto',
        listStyle: 'none',
    },
    containerLogin: {
        top: '7vh',
        left: '75vw',
        position: 'absolute',
        backgroundColor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        borderRadius: '8px',
        padding: '1em',
        textAlign: 'center',
    },
    containerLeft: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingLeft: '42px',
    },
    containerRight: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingRight: '64px',
    },
    containerFooter: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        bottom: 0,
        width: '100vw',
        lineHeight: '10px',
        backgroundColor: 'background.paper',
        color: 'primary.main',
        boxShadow: '0px -1px 1px rgba(255, 255, 255, 0.2)',
        paddingTop: '40px',
        paddingBottom: '30px',
    },
    containerBackground: {
        backgroundColor: 'background.paper',
        border: 'none',
        borderRadius: 0,
        minHeight: '100vh',
    },
    containerColumn: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    containerRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
    },
    containerFlexWrap: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    containerStar: {
        display: 'flex', 
        alignItems: 'end', 
        justifyContent: 'center', 
        margin: '20px 25px 0 45px'
    },
    containerGridList: {
        display: 'grid', 
        backgroundColor: 'background.default', 
        gridTemplateColumns: '3fr 1fr 1fr 1fr', 
        gridAutoRows: '100%',
        textAlign: 'center',
        alignItems: 'center',
        width: '68vw', 
        padding: '33px', 
        margin: '12px 0',
        cursor: 'pointer'
    },
    containerGridContainer: {
        marginBottom: '20em'
    },
    containerGridHeader: {
        display: 'grid',
        gridTemplateColumns: '3fr 1fr 1fr 1fr',
        gridAutoRows: '100%',
        marginBottom: '3em',
        textAlign: 'center',
    }
})