import {
    ColumnFlexContainer,
    TopStudent,
    StudentGrid,
    GridHeader,
    FlexContainer,
    RowFlexContainer,
    GridContainer,
    MarginLeftContainer } from '../components/StyledBox'
import { SelectField } from '../components/StyledTextFields'
import { DefaultTypography, HeaderTypography } from '../components/StyledTypographies'
import { Header, Footer, Background } from '../containers'

import { useDispatch, useSelector } from 'react-redux'
import { SortSelect, FilterSelect } from '../redux/actions/componentAction'

import { sort, filter, rows } from '../utils/selectFieldValues'
import { TablePagination } from '@mui/material'
import { setPage, setRowsPerPage } from '../redux/actions/componentAction'

export default function StudentList() {
    const dispatch = useDispatch()
    const component = useSelector((state) => state.component)

    const handleChangePage = (event, newPage) => {
        event.preventDefault()
        dispatch(setPage(newPage))
    }

    const handleChangeRowsPerPage = (event) => {
        event.preventDefault()
        dispatch(setRowsPerPage(+event.target.value))
        dispatch(setPage(0))
    }

    const handleChange = (type) => event => {
        event.preventDefault()
        switch(type) {
            case 'sort':
                return dispatch(SortSelect(event.target.value))
            case 'filter':
                return dispatch(FilterSelect(event.target.value))
            default:
                return
        }
    }
    
    return (
        <Background>
            <Header />
            {/* {query.map(({id, data})=>console.log({id, data}))} */}
            <ColumnFlexContainer>
                <MarginLeftContainer>
                    <HeaderTypography>
                        Top Student
                    </HeaderTypography>
                </MarginLeftContainer>
                <ColumnFlexContainer>
                    <FlexContainer>
                        {rows.slice(0, 4).map((data, index) => {
                                return <TopStudent
                                    key={index}
                                    name={data.name}
                                    review={data.review}
                                    rating={data.rating}
                                />
                        })}
                    </FlexContainer>
                </ColumnFlexContainer>
                <GridContainer>
                    <GridHeader>
                        <p></p>
                        <p></p>
                        <RowFlexContainer>
                            <DefaultTypography>Sort by:</DefaultTypography>
                            <SelectField defaultValue={component.sortSelect} onChange={handleChange('sort')} selection={sort} />
                        </RowFlexContainer>
                        <RowFlexContainer>
                            <DefaultTypography>Filter:</DefaultTypography>
                            <SelectField defaultValue={component.filterSelect} onChange={handleChange('filter')} selection={filter} /> 
                        </RowFlexContainer>
                        <p></p>
                        <DefaultTypography>{'Year & Section'}</DefaultTypography>
                        <DefaultTypography>{'Reviews'}</DefaultTypography>
                        <DefaultTypography>{'Rating'}</DefaultTypography>
                    </GridHeader>
                    {rows
                    .slice(component.page * component.rowsPerPage, component.page * component.rowsPerPage + component.rowsPerPage)
                    .map(({name, section, review, rating}, index) => {
                        return <StudentGrid
                            key={name+index+review}
                            number={index+1}
                            name={name}
                            section={section}
                            review={review+' reviews'}
                            rating={rating}
                        />
                    })}
                    <TablePagination
                        sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={component.rowsPerPage}
                        page={component.page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </GridContainer>
            </ColumnFlexContainer>
            <Footer />
        </Background>
    )
}