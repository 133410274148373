export const sort = [
        {
            value: 'Select',
            label: 'Select',
        },
        {
            value: 'Most Recent',
            label: 'Most Recent',
        }
]
export const filter = [
    {
        value: 'Select',
        label: 'Select',
    },
    {
        value: 'No Filter',
        label: 'No Filter',
    }
]
export const rows = [
    {
        avatar: '',
        name: 'Ronald Garcia',
        gender: 'Male',
        birthday: 'N/A',
        address: 'N/A',
        nickname: 'Onald',
        review: 5,
        rating: 5.0,
        skillsLang: 'Java',
        section: 'BSIT 4A',
    },
    {
        avatar: '',
        name: 'Klinth Nicolas',
        gender: 'Male',
        birthday: 'N/A',
        address: 'N/A',
        nickname: 'Klin',
        review: 5,
        rating: 5.0,
        skillsLang: 'Java',
        section: 'BSIT 4A',
    },
    {
        avatar: '',
        name: 'Marcy Bunag',
        gender: 'Male',
        birthday: 'N/A',
        address: 'N/A',
        nickname: 'Marsh',
        review: 5,
        rating: 5.0,
        skillsLang: 'Java',
        section: 'BSIT 4A',
    },
    {
        avatar: '',
        name: 'Gian Carranza',
        gender: 'Male',
        birthday: 'N/A',
        address: 'N/A',
        nickname: 'Yannie',
        review: 1,
        rating: 1.0,
        skillsLang: 'Java',
        section: 'BSIT 4A',
    },
    {
        avatar: '',
        name: 'Jonald Ramos',
        gender: 'Male',
        birthday: 'N/A',
        address: 'N/A',
        nickname: 'Jonnie',
        review: 5,
        rating: 3.0,
        skillsLang: 'Java',
        section: 'BSIT 4A',
    },
    {
        avatar: '',
        name: 'Earl Gerald',
        gender: 'Male',
        birthday: 'N/A',
        address: 'N/A',
        nickname: 'Earl',
        review: 2,
        rating: 3.0,
        skillsLang: 'Java',
        section: 'BSIT 4A',
    },
    {
        avatar: '',
        name: 'Jeric Ace Valmadrid',
        gender: 'Male',
        birthday: 'N/A',
        address: 'N/A',
        nickname: 'Ace',
        review: 5,
        rating: 4.0,
        skillsLang: 'Java',
        section: 'BSIT 4A',
    },
    {
        avatar: '',
        name: 'Marc Angelo Chiapco',
        gender: 'Male',
        birthday: 'N/A',
        address: 'N/A',
        nickname: 'Chappy',
        review: 5,
        rating: 2.0,
        skillsLang: 'Java',
        section: 'BSIT 4A',
    },
    {
        avatar: '',
        name: 'Elinald Mariano',
        gender: 'Male',
        birthday: 'N/A',
        address: 'N/A',
        nickname: 'Eli',
        review: 5,
        rating: 3.0,
        skillsLang: 'Java',
        section: 'BSIT 4A',
    },
    {
        avatar: '',
        name: 'Jym Cyruz',
        gender: 'Male',
        birthday: 'N/A',
        address: 'N/A',
        nickname: 'Jym',
        review: 5,
        rating: 3.0,
        skillsLang: 'Java',
        section: 'BSIT 4A',
    },
    {
        avatar: '',
        name: 'Cristofer Prago',
        gender: 'Male',
        birthday: 'N/A',
        address: 'N/A',
        nickname: 'Prago',
        review: 5,
        rating: 3.0,
        skillsLang: 'Java',
        section: 'BSIT 4A',
    },
    {
        avatar: '',
        name: 'Regine Manuel',
        gender: 'Female',
        birthday: 'N/A',
        address: 'N/A',
        nickname: 'Regine',
        review: 5,
        rating: 3.0,
        skillsLang: 'Java',
        section: 'BSIT 4A',
    },

]