import React from 'react'
import { Provider } from 'react-redux'
import RoutesContainer from './routes/RoutesContainer'
import store from './redux/store'
import { AuthProvider } from './auth/Authentication'

export default function App() {
  return (
    <AuthProvider>
      <Provider store={store}>
        <RoutesContainer />
      </Provider>
    </AuthProvider>
  )
}