import * as actionTypes from '../type'

export const toggleModal = (bool) => async (dispatch) => {
    try {
        await dispatch({
            type: actionTypes.TOGGLE_ACCOUNT_MODAL,
            payload: bool
        })
    } catch(e) {
        console.log('Error Toggle Account Modal: '+e)
    }
}
export const SortSelect = (value) => async (dispatch) => {
    try {
        await dispatch({
            type: actionTypes.SORT,
            payload: value
        })
    } catch(e) {
        console.log('Error Sort: '+e)
    }
}
export const FilterSelect = (value) => async (dispatch) => {
    try {
        await dispatch({
            type: actionTypes.FILTER,
            payload: value
        })
    } catch(e) {
        console.log('Error Filter: '+e)
    }
}
export const setPage = (value) => async (dispatch) => {
    try {
        await dispatch({
            type: actionTypes.SET_PAGE,
            payload: value
        })
    } catch(e) {
        console.log('Error Filter: '+e)
    }
}
export const setRowsPerPage = (value) => async (dispatch) => {
    try {
        await dispatch({
            type: actionTypes.SET_ROW_PAGE,
            payload: value
        })
    } catch(e) {
        console.log('Error Filter: '+e)
    }
}