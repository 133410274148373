import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import { Blog, CreateStudent, Error, StudentEvaluation, StudentList } from '../pages'
import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material'
import { theme } from '../customizations/theme'
import { useSelector, useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { setStudDocs } from '../redux/actions/studentActions'
// import { useAuth } from '../auth/Authentication'

export default function RoutesContainer() {
    const dispatch = useDispatch()
    const darkMode = useSelector((state) => state.ui.darkMode)
    const Theme = createTheme(theme(darkMode))
    const THEME = responsiveFontSizes(Theme)
    
    useEffect(() => {
        dispatch(setStudDocs())
    }, [dispatch])

    // const PrivateOutlet = () => {
    //     return currentUser ? <Outlet /> : <Navigate to="/" />;
    // }
    
    // const PublicOutlet = () => {
    //     const { currentUser } = useAuth()
    //     return !currentUser ? <Outlet /> : <Navigate to="/blog" />;
    // }

    return (
        <ThemeProvider theme={THEME}>
            <Router>
                <Routes>
                    <Route exact path='/' element={<Navigate replace to='/student-list' />} />
                    <Route path='/create' element={<CreateStudent />} />
                    <Route path='/blog' element={<Blog />} />
                    {/* <Route path='/private' element={<PrivateOutlet />}>
                        <Route path='' element={<Blog />} />
                    </Route> */}
                    <Route exact path='student-evaluation/:studentID' element={<StudentEvaluation />} />
                    <Route exact path='/student-list' element={<StudentList />} />
                    <Route path='*' element={<Error />} />
                </Routes>
            </Router>
        </ThemeProvider>
    )
}
