import * as actionTypes from '../type'

const initialState = {
    toggleAccountModal: false,
    sortSelect: 'Select',
    filterSelect: 'Select',
    page: 0,
    rowsPerPage: 10,
}

function componentReducer(state=initialState, action){
    switch(action.type) {
        case actionTypes.TOGGLE_ACCOUNT_MODAL:
            return {
                ...state,
                toggleAccountModal: action.payload,
            }
        case actionTypes.SORT:
            return {
                ...state,
                sortSelect: action.payload,
            }
        case actionTypes.FILTER:
            return {
                ...state,
                filterSelect: action.payload,
            }
        case actionTypes.SET_PAGE:
            return {
                ...state,
                page: action.payload,
            }
        case actionTypes.SET_ROW_PAGE:
            return {
                ...state,
                rowsPerPage: action.payload,
            }
        case actionTypes.ON_ERROR:
            return {
                ...state,
            }
        default:
            return {
                ...state,
            }
    }
}

export default componentReducer