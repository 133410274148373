import { useState } from 'react'
import { Box, IconButton } from '@mui/material'
import StarOutlinedIcon from '@mui/icons-material/StarOutlined'
import { Container } from '../customizations/containers'
import { DefaultTypography } from './StyledTypographies'

import { useNavigate } from 'react-router-dom'

const style = Container()
const index = [0, 1, 2, 3, 4]

export const StarDynamicContainer = ({...props}) => {
    const [state, setState] = useState(0)

    return <Box {...props}
        sx={style.containerStar}>
        {index.map((value) => {
            return <IconButton key={value}
                disableRipple
                onClick={(()=>{setState(value+1)})}
                sx={{width: '15px', height: '15px', margin: '0.1em'}}>
                <StarOutlinedIcon sx={{color: state > value ? 'primary.main' : 'text.disabled'}}/>
            </IconButton>
        })}
    </Box>
}
export const StarStaticContainer = ({...props}) => {
    const Color = (value) => {
        if (value >= 3)
            return 'primary.main'
        return 'error.main'
    }

    return <Box {...props}
        sx={style.containerStar}>
        {index.map((value) => {
            return <StarOutlinedIcon key={value} sx={{
                color: props.rating > value ?  Color(props.rating) : 'text.disabled',
                width: '15px',
                height: '15px',
                margin: '0.1em'}
            }/>
        })}
    </Box>
}
export const ColumnFlexContainer = ({children, ...props}) => {
    return <Box {...props}
        sx={style.containerColumn}>
        {children}
    </Box>
}
export const RowFlexContainer = ({children, ...props}) => {
    return <Box {...props}
        sx={style.containerRow}>
        {children}
    </Box>
}
export const FlexContainer = ({children}) => {
    return <Box sx={style.containerFlexWrap}>
        {children}
    </Box>
}
export const TopStudent = ({...props}) => {
    const navigate = useNavigate()
    
    return <Box sx={{display: 'flex', flex: '25% 0 auto', alignSelf: 'center', justifyContent: 'center', cursor: 'pointer'}}
        onClick={(()=>{
            navigate(`/student-evaluation/${props.name}+${props.review}+${props.rating}+${props.section}`)
        })}>
        <Box sx={{backgroundColor: 'background.default', width: '100%', padding: '20px', margin: '30px', borderRadius: '8px'}}>
            <Box sx={{display: 'flex'}}>
                <Box sx={{width: '42px', height: '42px', backgroundColor: 'white'}}/>
                <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'start', marginLeft: '4px'}}>
                    <DefaultTypography>{props.name}</DefaultTypography>
                    <DefaultTypography>{props.review+' reviews'}</DefaultTypography>
                </Box>
            </Box>
            <StarStaticContainer rating={props.rating}/>
        </Box>
    </Box>
}
export const StudentGrid = ({...props}) => {
    const navigate = useNavigate()
    return <Box sx={style.containerGridList}
        onClick={(()=>{
            navigate(`/student-evaluation/${props.name}+${props.review}+${props.rating}+${props.section}`)
        })}>
        <RowFlexContainer>
            <DefaultTypography>{props.number}</DefaultTypography>
            <Box sx={{width: '37px', height: '37px', backgroundColor: 'white'}}/>
            <DefaultTypography>{props.name}</DefaultTypography>
        </RowFlexContainer>
        <DefaultTypography>{props.section}</DefaultTypography>
        <DefaultTypography>{props.review}</DefaultTypography>
        <StarStaticContainer rating={props.rating}/>
    </Box>
}
export const GridContainer = ({children}) => {
    return <Box
        sx={style.containerGridContainer}>
        {children}
    </Box>
}
export const GridHeader = ({children, ...props}) => {
    return <Box {...props}
        sx={style.containerGridHeader}>
        {children}
    </Box>
}
export const MarginLeftContainer = ({children}) => {
    return <Box
        sx={{
            margin: '38px 0 0 200px',
            alignSelf: 'start',
            justifySelf: 'center',
        }}>
        {children}
    </Box>
}