import * as actionTypes from '../type'

const initialState = {
    darkMode: true,
}

function uiReducer(state=initialState, action){
    switch(action.type) {
        case actionTypes.SET_DARK_THEME:
            return {
                ...state,
                darkMode: action.payload,
            }
        case actionTypes.ON_ERROR:
            return {
                ...state,
            }
        default:
            return {
                ...state,
            }
    }
}

export default uiReducer