import { Link } from 'react-router-dom'
import { Box, IconButton } from '@mui/material'
import { Container } from '../customizations/containers'
import { Twitter, Discord, Facebook } from '../icon'

export default function Footer() {
    const style = Container()

    return (
        <Box sx={style.containerFooter}>
            <Box sx={{display: 'flex'}}>
                <IconButton sx={{margin: '0 15px'}}>
                    <Twitter/>
                </IconButton>
                <IconButton sx={{margin: '0 15px'}}>
                    <Discord/>
                </IconButton>
                <IconButton sx={{margin: '0 15px'}}>
                    <Facebook/>
                </IconButton>
            </Box>
            <Box sx={{display: 'flex', alignItems: 'center'}}>
                <p style={{color: '#62666D'}}>Contact us:</p>
                <Link style={{textDecoration: 'none', color: '#26CE8D', margin: '0 15px'}} to='#'>support@studentreview.com</Link>
            </Box>
            <Box sx={{color: '#62666D'}}>© 2021 Student Review. All Rights Reserved.</Box>
            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-around'}}>
                <Link style={{textDecoration: 'none', color: '#26CE8D', margin: '0 15px'}} to='#'>Terms of Service</Link>
                <p style={{color: '#62666D'}}>|</p>
                <Link style={{textDecoration: 'none', color: '#26CE8D', margin: '0 15px'}} to='#'>Privacy Policy</Link>
            </Box>
        </Box>
    )
}
