import Box from '@mui/material/Box'
import { NavLink } from 'react-router-dom'
import { Container, ListStyle } from '../customizations/containers'
import { useAuth } from '../auth/Authentication'


export const StyledNavBar = () => {
    const { currentUser } = useAuth()
    const style = Container()
    const list = ListStyle()

    return (
        <Box sx={style.containerNavBar}>
            <li>
                <NavLink style={({ isActive }) => isActive ? list.containerActive : list.containerLink} to='/student-list'>
                    Student List
                </NavLink>
            </li>
            <li>
                <NavLink style={({ isActive }) => isActive ? list.containerActive : list.containerLink} to={currentUser ? `/student-evaluation/${currentUser.email}` : '/'}>
                    Student Evaluation
                </NavLink>
            </li>
            <li>
                <NavLink style={({ isActive }) => isActive ? list.containerActive : list.containerLink} to='/blog'>
                    Blog
                </NavLink>
            </li>
        </Box>
    )
}
