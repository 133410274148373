export const theme = (darkMode) => ({
    palette: {
        mode: darkMode ? 'dark' : 'light',
        ...(darkMode ? {
            // palette values for dark mode
            primary: {
                main: '#26CE8D',
                light: '#03a5fc',
            },
            secondary: {
                main: '#5C527F',
            },
            divider: '#FFF',
            background: {
                paper: '#131414',
                default: '#1E1F20',
            },
            text: {
                primary: '#D1D4C9',
                secondary: '#62666D',
                disabled: '#2C2F31'
            },
        } : {
            // palette values for light mode
            primary: {
                main: '#DE8971',
            },
            secondary: {
                main: '#7B6079',
            },
            divider: '#000',
            background: {
                paper: '#FFE9D6',
                default: '#A7D0CD',
            },
            text: {
                    primary: '#000',
                    secondary: '#333',
            },
        }),
    },
    typography: {
        fontFamily: 'Roboto'
    },
})