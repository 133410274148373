import { useAuth } from '../auth/Authentication'

import { Box, IconButton } from '@mui/material'
import FacebookIcon from '@mui/icons-material/Facebook'
import GoogleIcon from '@mui/icons-material/Google'
import TwitterIcon from '@mui/icons-material/Twitter'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'
import { Grid } from '../icon'

import { StyledNavBar } from '../components/StyledNavBar'
import { DefaultTypography, HeaderTypography } from '../components/StyledTypographies'
import { SearchTextField } from '../components/StyledTextFields'
import { Container } from '../customizations/containers'
import { useNavigate } from 'react-router-dom'

import { useSelector, useDispatch } from 'react-redux'
import { toggleModal } from '../redux/actions/componentAction'

export default function Header() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const style = Container()
    const accountBoolean = useSelector((state) => state.component.toggleAccountModal)
    const { googleLogin, fbLogin, currentUser, signout } = useAuth()

    const handleGoogle = () => async (event) => {
        try {
            event.preventDefault()
            await googleLogin()
            navigate('/')
        } catch (e) {
            console.log('Error with Google authentication ' + e)
        }
    }
    const handleFacebook = () => async (event) => {
        try {
            event.preventDefault()
            await fbLogin()
        } catch (e) {
            console.log('Error with Facebook authentication ' + e)
        }
    }
    
    const handleToggleModal = () => event => {
        event.preventDefault()
        dispatch(toggleModal(!accountBoolean))
    }
    
    const handleSignOut = () => event => {
        event.preventDefault()
        signout()
        dispatch(toggleModal(!accountBoolean))
        navigate('/')

    }

    return (
        <Box sx={style.containerHeader}>
            <Box sx={style.containerLeft}>
                <HeaderTypography>Student Review</HeaderTypography>
                <Grid />
            </Box>
            <StyledNavBar />
            <Box sx={style.containerRight}>
                <IconButton onClick={handleToggleModal()}>
                    <AccountCircleOutlinedIcon />
                </IconButton>
                <SearchTextField />
            </Box>
            {accountBoolean && <Box sx={style.containerLogin}>
                <DefaultTypography>{currentUser ? currentUser.email : 'Sign In'}</DefaultTypography>
                <DefaultTypography>{currentUser ? 'Signed-In!' : 'Sign in to review and rate students.'}</DefaultTypography>
                {currentUser && <IconButton onClick={handleSignOut()}>Sign-Out</IconButton>}
                {!currentUser && <Box>
                    <IconButton>
                        <TwitterIcon />
                    </IconButton>
                    <IconButton onClick={handleGoogle()}>
                        <GoogleIcon />
                    </IconButton>
                    <IconButton onClick={handleFacebook()}>
                        <FacebookIcon />
                    </IconButton>
                </Box>}
            </Box>}
        </Box>
    )
}
