import { Header, Footer, Background } from '../containers'

export default function Blog() {
    return (
        <Background>
            <Header />
            <Footer />
        </Background>
    )
}
