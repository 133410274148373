import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getFirestore, addDoc, collection, getDocs } from 'firebase/firestore'

export const firebaseApp = initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
})

const db = getFirestore(firebaseApp)
const collectionName = 'students'

export const auth = getAuth(firebaseApp)
export const addStudents = async (value) => {
    console.log(`Succesfully added ${value.name} into ${collectionName} collection`)
    await addDoc(collection(db, collectionName), value)
}

export const getDocuments = async() => {
    let array = []
    const querySnapshot = await getDocs(collection(db, collectionName))
    querySnapshot.forEach((value)=>{
        array.push({id: value.id, data: value.data()})
    })
    return array
}
