import * as actionTypes from '../type'

import { getDocuments } from '../../auth/Firebase'

export const setStudDocs = () => async (dispatch) => {
    try {
        await dispatch({
            type: actionTypes.SET_DOCUMENTS,
            payload: getDocuments().then((value)=>{
                return value
            })
        })
    } catch(e) {
        console.log('Error with Student Data: '+e)
    }
}