import Box from '@mui/material/Box'
import { Container } from '../customizations/containers'

export default function Background({children}) {
    const style = Container()

    return (
        <Box sx={style.containerBackground}>
            {children}
        </Box>
    )
}
