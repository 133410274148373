export const Text = () => ({
    textFieldSearch: {
        backgroundColor: 'background.paper'
    },
    textFieldSelect: {
        margin: '0 10px 0 5px'
    },
    textFieldDefault: {
        margin: '10px'
    },
})