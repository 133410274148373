import { TextField, IconButton, MenuItem } from '@mui/material'
import { Text } from '../customizations/textfields'
import SearchIcon from '@mui/icons-material/Search'

const style = Text()

export const SearchTextField = ({children, ...props}) => {
    return <>
            <IconButton sx={{position: 'absolute', zIndex: '500', right: '240px'}}>
                {<SearchIcon />}
            </IconButton>
            <TextField {...props}
                sx={style.textFieldSearch}
                size='small'
                InputProps={{
                    inputProps: {
                        style: { textAlign: 'right' }
                    }
                }}>
                {children}
            </TextField>
        </>
}
export const SelectField = ({...props}) => {
    return <TextField {...props} select
        sx={style.textFieldSelect}>
        {props.selection.map(({value, label}) => {
            return label === 'Select' 
            ? <MenuItem sx={{display: 'none'}} disabled key={value} value={value} selected>{label}</MenuItem> 
            : <MenuItem key={value} value={value}>{label}</MenuItem>
        })}
    </TextField>
}
export const DefaultTextField = ({...props}) => {
    return <TextField {...props}
        sx={style.textFieldDefault}
    />
}