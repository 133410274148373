import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { ColumnFlexContainer } from '../components/StyledBox'
import { DefaultTextField } from '../components/StyledTextFields'
import { Background } from '../containers'

import { Button } from '@mui/material'
import { ArrowBack } from '@mui/icons-material'

import { addStudents } from '../auth/Firebase'
import { rows } from '../utils/selectFieldValues'

export default function CreateStudent() {
    const navigate = useNavigate()
    const [state, setState] = useState({
        avatar: null,
        name: null,
        gender: null,
        birthday: null,
        address: null,
        nickname: null,
        review: null,
        rating: null,
        skillsLang: null,
        section: null,
    })

    const handleChange = (props) => event => {
        setState((previous) => ({...previous, [props]: event.target.value}))
    }

    const handleSubmit = () => (event) => {
        event.preventDefault()
        try {
            addStudents(state)
        } catch(e) {
            console.log('Can\'t submit data, Error: '+e)
        }
    }

    return (
        <Background>
            <Button onClick={(()=>{ navigate('/') })}>
                <ArrowBack />
            </Button>
            <ColumnFlexContainer>
                <DefaultTextField label='Name' onChange={handleChange('name')}/>
                <DefaultTextField label='Gender' onChange={handleChange('gender')}/>
                <DefaultTextField label='Birthday' onChange={handleChange('birthday')}/>
                <DefaultTextField label='Address' onChange={handleChange('address')}/>
                <DefaultTextField label='Nickname' onChange={handleChange('nickname')}/>
                <DefaultTextField label='Skills / Languages' onChange={handleChange('skillsLang')}/>
                <DefaultTextField label='Section' onChange={handleChange('section')}/>
                <Button variant='contained' onClick={handleSubmit()}>
                    Submit
                </Button>
                <Button onClick={()=>{
                    rows.forEach(({avatar, name, gender, birthday, address, nickname, review, rating, skillsLang, section})=>{
                        return addStudents({
                            date: new Date(),
                            avatar: avatar,
                            name: name,
                            gender: gender,
                            birthday: birthday,
                            address: address,
                            nickname: nickname,
                            review: review,
                            rating: rating,
                            skillsLang: skillsLang,
                            section: section,
                        })
                    })
                }}>
                    Auto-Fill
                </Button>
            </ColumnFlexContainer>
        </Background>
    )
}
