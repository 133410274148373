import { Typography } from '@mui/material'

export const DefaultTypography = ({children}) => {
    return (
        <Typography sx={{
            color: '#FFFFFF',
            textAlign: 'center'
        }}>
            {children}
        </Typography>
    )
}
export const HeaderTypography = ({children}) => {
    return (
        <Typography variant='h5' sx={{
            color: '#FFFFFF',
        }}>
            {children}
        </Typography>
    )
}
