import React, { useContext, useState, useEffect } from 'react'
import { auth } from './Firebase'
import { 
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signInAnonymously,
    signOut,
    onAuthStateChanged,
    sendPasswordResetEmail,
    updateEmail,
    updatePassword,
    updatePhoneNumber,
    updateProfile,
    signInWithPopup,
    EmailAuthProvider,
    GoogleAuthProvider,
    FacebookAuthProvider
} from 'firebase/auth'

const AuthContext = React.createContext()

export function useAuth() {
    return useContext(AuthContext)
}

export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState('')
    const [loading, setLoading] = useState(true)

    function signin(email, password) {
        return signInWithEmailAndPassword(auth, email, password)
    }
    function signup(email, password) {
        return createUserWithEmailAndPassword(auth, email, password)
    }
    function anonymous() {
        return signInAnonymously(auth)
    }
    function signout() {
        return signOut(auth)
    }
    function resetPassword(email) {
        return sendPasswordResetEmail(auth, (email))
    }
    function upEmail(email) {
        return updateEmail(currentUser, (email))
    }
    function upPassword(password) {
        return updatePassword(currentUser, (password))
    }
    function upPhone() {
        return updatePhoneNumber(currentUser)
    }
    function upProfile(name, url) {
        return updateProfile(currentUser, {displayName: name, photoURL: url})
    }
    function emailAuth(email, password) {
        return EmailAuthProvider.credential(email, password)
    }
    function googleLogin() {
        const googleAuthProvider = new GoogleAuthProvider()
        return signInWithPopup(auth, googleAuthProvider)
        // .then((result) => {
        //     // This gives you a Google Access Token. You can use it to access the Google API.
        //     const credential = GoogleAuthProvider.credentialFromResult(result);
        //     const token = credential.accessToken;
        //     // The signed-in user info.
        //     const user = result.user;
        // })
    }
    function fbLogin() {
        const fbAuthProvider = new FacebookAuthProvider()
        return signInWithPopup(auth, fbAuthProvider)
        // .then((result) => {
        //     // The signed-in user info.
        //     const user = result.user;

        //     // This gives you a Facebook Access Token. You can use it to access the Facebook API.
        //     const credential = FacebookAuthProvider.credentialFromResult(result);
        //     const accessToken = credential.accessToken;
        // })
    }

    useEffect(()=>{
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setCurrentUser(user)
            setLoading(false)
        })
        return unsubscribe
    }, [])

    const value = {
        currentUser,
        signin,
        signup,
        signout,
        anonymous,
        resetPassword,
        upEmail,
        upPassword,
        upPhone,
        upProfile,
        emailAuth,
        googleLogin,
        fbLogin
    }

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    )
}